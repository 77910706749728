@import "../scss/colors";
@import "../scss/breakpoints";

.table {
  width: 100%;

  &.key-value {
    td:first-child {
      font-weight: 600;
    }
  }

  td {
    padding: 10px;
    vertical-align: middle;
    font-size: 14px;
    color: #bababa;

    @include mediaMax($small) {
      font-size: 12px;
      line-height: 1.4;
      padding: 5px 10px;
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;

      li {
        font-size: 14px;

        @include mediaMax($small) {
          font-size: 12px;
        }
      }
    }
  }

  tr {
    &:nth-child(odd) {
     background: #26252B;
    }
  }
}
