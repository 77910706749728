@import "colors";
@import "../scss/breakpoints";

.swiper {
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  max-width: 100%;
  width: 100%;
  margin: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  /* Fix of Webkit flickering */
  z-index: 1;
  display: block;

  &-vertical > .swiper-wrapper {
    flex-direction: column;
  }

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
  }

  &-android .swiper-slide,
  &-ios .swiper-slide,
  &-wrapper {
    transform: translate3d(0px, 0, 0);
  }

  &-horizontal {
    touch-action: pan-y;
  }

  &-vertical {
    touch-action: pan-x;
  }

  &-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    transition-property: transform;
    height: calc(100vh - 322px);
    background-color: #1c1b22;

    @include mediaMax($large) {
      height: 400px;
    }

    @include mediaMax($small) {
      height: 300px;
    }

    &:hover {
      .image-parameter-result,
      .image-result {
        @include mediaMin($large) {
          opacity: 1;
          pointer-events: auto;
        }
      }

      &:before {
        @include mediaMin($large) {
          opacity: 1;
        }
      }
    }

    &.is-open {
      &:before {
        @include mediaMax($large) {
          opacity: 1;
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      pointer-events: none;
      opacity: 0;
      background: rgba(0,0,0,0.8);
      transition: 0.200s ease-in-out;
    }

    &-invisible-blank {
      visibility: hidden;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      align-self: center;
      position: relative;
      z-index: 2;
    }

    span {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: cover;
      background-repeat: repeat;
      background-position: center;
      opacity: 0.1;
    }
  }

  &-autoheight,
  &-autoheight .swiper-slide {
    height: auto;
  }

  &-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }

  &-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &-button {
    &-prev,
    &-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #4c4d4f;

      &:after {
        content: "";
        display: block;
        width: 26px;
        height: 36px;
        background-image: url('./../assets/svg/arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px auto;
      }
    }

    &-prev.swiper-button-disabled,
    &-next.swiper-button-disabled {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }

    &-prev.swiper-button-hidden,
    &-next.swiper-button-hidden {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }
  }

  &-button-prev {
    left: 0;
    right: auto;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:after {
      transform: rotate(90deg);
    }
  }

  &-button-next {
    right: 0;
    left: auto;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &:after {
      transform: rotate(-90deg);
    }
  }


  &-pagination {
    display: flex;
    width: 100%;
    height: 104px;
    padding: 2px 0;
    gap: 2px;
    background: #4c4d4f;
    box-sizing: border-box;
  }

  &-pagination-bullet {
    height: 100px;
    width: auto;
    flex-grow: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    cursor: pointer;
    opacity: 0.4;

    &-active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  &-thumbs {
    height: 100px;
    padding: 5px 0;
    background: #252525;
    border-radius: 0;

    .swiper-slide {
      height: 90px;
      opacity: 0.4;
      padding: 2px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 0;
      transition: 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      img {
        height: 100px;
        width: auto;
        align-self: center;
      }
    }
  }
}
