@import "../scss/colors";
@import "../scss/breakpoints";

.loraModal {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  .form-group {
    &:nth-child(1) {
      width: 100%;
      min-width: 895px;

      @include mediaMax($large) {
        min-width: unset;
      }
    }

    &:nth-child(2) {
      width: calc(100% - 170px);

      @include mediaMax($small) {
        width: 100%;
      }
    }

    &:nth-child(3) {
      width: 150px;
      padding: 0;
      background: transparent;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: center;
      height: 80px;

      @include mediaMax($small) {
        width: 100%;
        flex-flow: unset;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }
    }

    .btnBig {
      width: 100%;
      margin-top: 5px;

      @include mediaMax($small) {
        margin-top: 0;
      }
    }
  }
}

.loraItem {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 4px;
  background: #26252B;
  overflow: hidden;
  margin-bottom: 15px;
}

.loraModel {
  width: calc(100% - 200px);
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  max-width: 695px;

  @include mediaMax($medium) {
    width: calc(100% - 100px);
  }

  @include mediaMax($small) {
    width: 100%;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
  }

  .form-group {
    padding: 20px 0 0 0;
    margin-bottom: 0;
  }
}

.loraImg {
  width: 200px;

  @include mediaMax($medium) {
    width: 100px;
  }

  @include mediaMax($small) {
    width: 100%;
  }

  a {
    display: flex;
  }

  img {
    width: 100%;
    height: 100%;
    align-self: center;
    object-fit: cover;
  }
}

.loraModel--desc {
  margin: 20px 0;
  font-size: 14px;

  .text-warning {
    font-size: 12px;
    margin: 5px 0 0 0;
  }
}

.loraModel--top {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.loraModel--bottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  @include mediaMax($small) {
    justify-content: space-between;
  }

  .btnBig {
    margin-left: 20px;
  }

  a {
    font-size: 14px;
  }
}

.loraList {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  label {
    order: 1;
    width: 100%;
  }

  p {
    order: 3;
    width: 100%;
    background: #1d1e1f;
    border: 1px solid #4c4d4f;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    padding: 10px 15px;
    margin-top: 5px;

    @include mediaMax($small) {
      font-size: 12px;
    }
  }

  button.btnBig {
    order: 4;
    margin: 15px 0 0 0;
  }

  ul {
    padding: 0;
    margin: 0;
    order: 2;
    width: 100%;
  }

  li {
    width: 100%;
    display: inline-flex;
    align-items: center;
    position: relative;
    line-height: 2;
    padding: 5px;
    list-style: none;
    background: #1d1e1f;
    margin: 3px 0;
    border: 1px solid #4c4d4f;
    border-radius: 4px;

    &.lora-name {
      width: calc(100% - 65px);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top:12px;
    }

    strong {
      max-width: 100%;
      width: 100%;
    }
  }

  .ng-fa-icon {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    //background: white;
  }

  button.icon-button {
    background: #232323;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;

    &:nth-of-type(1) {
      background: $primaryColor;
      margin-right: 5px;

      fa-icon {
        font-size: 12px;
      }
    }

    &:nth-of-type(2) {
      background: $dangerColor;
    }

    fa-icon {
      color: white;
      font-size: 14px;
    }
  }
}
