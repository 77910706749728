$topMenuBreakpoint: 880px;
$topMenuBreakpoint2: 684px;
$topMenuBreakpoint3: 640px;
$topMenuBreakpoint4: 430px;

$extraSmall: 420px;
$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;
$extraExtraLarge: 1400px;

@mixin mediaMin($minWidth) {
  @media screen and (min-width: $minWidth) {
    @content
  }
}

@mixin mediaMax($maxWidth) {
  @media screen and (max-width: $maxWidth) {
    @content
  }
}
