$mainTextColor: #fbfbfe;
$mainTextDisabledColor: #8d9095;
$bodyBackground: #1c1b22;
$bodyBackgroundHover: #25242a;
$primaryColor: #409eff;
$primaryDarkColor: #398ade;

$dangerColor: #c54040;
$warningColor: orange;
$borderColor: #4c4d4f;
$cardBackground: #1d1e1f;
$shadowColor: rgba(0, 0, 0, 0.72);
$successColor: #51a351;
