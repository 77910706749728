@import 'ngx-toastr/toastr';
@import 'tom-select/dist/scss/tom-select.default.scss';
@import "tippy.js/dist/tippy.css";

@import "scss/colors";

@import "scss/forms";
@import "scss/cols";
@import "scss/table";
@import "scss/animations";
@import "scss/breakpoints";
@import "scss/card";
@import "scss/tom-select";
@import "scss/swiper";
@import "scss/pagination";
@import "scss/generate-image";

:root {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $bodyBackground;
  color: $mainTextColor;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0;
  overflow-y: scroll;

  @include mediaMax($topMenuBreakpoint) {
    padding-top: 105px;
  }

  @include mediaMax($small) {
    padding-top: 0;
  }

  &::-webkit {
    &-scrollbar {
      width: 10px;
    }

    &-scrollbar {
      &-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
      &-thumb {
        background-color: white;
      }
    }
  }
}

p {
  line-height: 1.5;
  font-size: 16px;
  margin-bottom: 20px;
}

a {
  color: $primaryColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

h1, h2, h3 {
  font-weight: normal;
  line-height: 1.5;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-normal {
  font-weight: normal !important;
}

ul.about {
  margin: 10px 0 20px 10px;
}

li {
  margin-left: 15px;
  font-size: 16px;
  line-height: 1.5;
}

.position-relative {
  position: relative;
}

.text-success {
  color: $successColor;
}

.text-danger {
  color: $dangerColor;

  a {
    font-weight: bold;
    text-decoration: underline;
    color: $dangerColor;

    &:hover {
      text-decoration: none;
    }
  }
}

.hr {
  width: 100%;
  display: block;
  background: #26252B;
  height: 4px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.text-warning {
  color: $warningColor;
}

.text-primary {
  color: $primaryColor;
}

.display-block {
  display: block;
}

.text-bold {
  font-weight: bold;
}

.col-md-12 {
  width: 100%;
}
