@use "sass:math";
@import "../scss/breakpoints";

.flex {
  display: flex;
}

.row {
  @extend .flex;
}

@mixin col($i) {
  flex-basis: 100% * math.div($i, 12);
}

.col-md-1 {
  @include mediaMin($medium) {
    @include col(1);
  }
}
.col-md-2 {
  @include mediaMin($medium) {
    @include col(2);
  }
}
.col-md-3 {
  @include mediaMin($medium) {
    @include col(3);
  }
}
.col-md-4 {
  @include mediaMin($medium) {
    @include col(4);
  }
}
.col-md-5 {
  @include mediaMin($medium) {
    @include col(5);
  }
}
.col-md-6 {
  @include mediaMin($medium) {
    @include col(6);
  }
}
.col-md-7 {
  @include mediaMin($medium) {
    @include col(7);
  }
}
.col-md-8 {
  @include mediaMin($medium) {
    @include col(8);
  }
}
.col-md-9 {
  @include mediaMin($medium) {
    @include col(9);
  }
}
.col-md-10 {
  @include mediaMin($medium) {
    @include col(10);
  }
}
.col-md-11 {
  @include mediaMin($medium) {
    @include col(11);
  }
}
.col-md-12 {
  @include mediaMin($medium) {
    @include col(12);
  }
}

.col-sm-1 {
  @include mediaMin($small) {
    @include col(1);
  }
}
.col-sm-2 {
  @include mediaMin($small) {
    @include col(2);
  }
}
.col-sm-3 {
  @include mediaMin($small) {
    @include col(3);
  }
}
.col-sm-4 {
  @include mediaMin($small) {
    @include col(4);
  }
}
.col-sm-5 {
  @include mediaMin($small) {
    @include col(5);
  }
}
.col-sm-6 {
  @include mediaMin($small) {
    @include col(6);
  }
}
.col-sm-7 {
  @include mediaMin($small) {
    @include col(7);
  }
}
.col-sm-8 {
  @include mediaMin($small) {
    @include col(8);
  }
}
.col-sm-9 {
  @include mediaMin($small) {
    @include col(9);
  }
}
.col-sm-10 {
  @include mediaMin($small) {
    @include col(10);
  }
}
.col-sm-11 {
  @include mediaMin($small) {
    @include col(11);
  }
}
.col-sm-12 {
  @include mediaMin($small) {
    @include col(12);
  }
}

.col-xs-1 {
  @include mediaMin($extraSmall) {
    @include col(1);
  }
}
.col-xs-2 {
  @include mediaMin($extraSmall) {
    @include col(2);
  }
}
.col-xs-3 {
  @include mediaMin($extraSmall) {
    @include col(3);
  }
}
.col-xs-4 {
  @include mediaMin($extraSmall) {
    @include col(4);
  }
}
.col-xs-5 {
  @include mediaMin($extraSmall) {
    @include col(5);
  }
}
.col-xs-6 {
  @include mediaMin($extraSmall) {
    @include col(6);
  }
}
.col-xs-7 {
  @include mediaMin($extraSmall) {
    @include col(7);
  }
}
.col-xs-8 {
  @include mediaMin($extraSmall) {
    @include col(8);
  }
}
.col-xs-9 {
  @include mediaMin($extraSmall) {
    @include col(9);
  }
}
.col-xs-10 {
  @include mediaMin($extraSmall) {
    @include col(10);
  }
}
.col-xs-11 {
  @include mediaMin($extraSmall) {
    @include col(11);
  }
}
.col-xs-12 {
  @include mediaMin($extraSmall) {
    @include col(12);
  }
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-justify-content-center {
  justify-content: center;
}

.mt-1 {
  margin-top: 1vh;
}

.mt-2 {
  margin-top: 2vh;
}

.mt-15 {
  margin-top: 15vh;
}

.mb-1 {
  margin-bottom: 1vh;
}

.mb-2 {
  margin-bottom: 2vh;
}

.mr-05 {
  margin-right: 0.5vw;
}

.mr-1 {
  margin-right: 1vw;
}

.ml-1 {
  margin-left: 1vw;
}
