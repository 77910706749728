@import "colors";
@import "../scss/breakpoints";

.form-group {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  background: #26252B;

  @include mediaMax($small) {
    padding: 15px;
    margin-bottom: 10px;
  }

  & > * {
    display: block;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  label {
    position: relative;
    font-size: 14px;
    padding-right: 25px;

    @include mediaMax($small) {
      font-size: 12px;
    }

    app-tooltip {
      position: absolute;
      right: 0;

      @include mediaMax($small) {
        font-size: 16px;
      }
    }
  }

  input {
    margin-bottom: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  small {
    line-height: 1.7;
    font-size: 12px;
    color: #aeaeae;
  }

  .ts-wrapper {
    margin-bottom: 0;
    min-height: 40px;
  }

  .ts-wrapper.single .ts-control {
    padding-right: 40px !important;
  }

  .ts-control {
    z-index: 0;
  }
}

.form-control {
  width: 100%;
  color: $mainTextColor;
  height: 40px;
  line-height: 40px;
  background: $bodyBackground;
  padding: 5px 15px;
  border: 1px solid $borderColor;
  border-radius: 5px;
  transition: border-color 0.2s ease-in-out;
  font-size: 14px;

  &:hover, &:focus {
    border-color: $primaryColor;
  }

  &.textarea {
    margin-bottom: 0;
  }
}

.btn {
  border: none;
  color: $bodyBackground;
  font-weight: bold;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;

  @include mediaMax($small) {
    padding: 1vw 3vw;
    font-size: 3vw;
    line-height: 1;
    border-radius: 1vw;
  }

  &[disabled] {
    background: $borderColor;
    cursor: not-allowed;
    color: $mainTextDisabledColor;
  }

  &.btnBig {
    padding: 10px 15px;
  }
}

.btn-primary {
  background: $primaryColor;
  color: $mainTextColor;
}

.btn-danger {
  background: $dangerColor;
  color: $mainTextColor;
}

.btn-long {
  display: block;
  width: 100%;
  padding: 15px;

  @include mediaMax($small) {
    padding: 1vw 3vw;
  }
}

label:has(+ [required]), label.required {
  &:after {
    padding-left: 3px;
    content: '*';
    color: $dangerColor;
  }
}

textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 36px;
  max-height: 500px;
  font-family: inherit;
  line-height: 26px !important;
}

label {
  font-weight: bold;
}

input, select, textare {
  &.ng-invalid {
    border: 1px solid $dangerColor !important;
  }
}
